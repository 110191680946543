// components
import { Title } from '../Title/Title';
import GalleryLightbox from './gallery-lightbox';
import { ImageGalleryDefaultProps } from './image-gallery-default';
import Thumbnail from './thumbnail';

// utils
import { TitleFormats } from '../Title/title.types';

export function ImageGalleryLandingpage({
  imagesPerRow,
  imgResolutionsPicture,
  sectionId,
  border,
  title,
  subtitle,
  isChild,
  isSectionReference,
  imageGallery,
  isOpen,
  maxLength,
  setMaxLength,
  setIsOpen,
  currentItemIndex,
  setCurrentItemIndex,
  catalogName,
}: Readonly<ImageGalleryDefaultProps>) {
  const borderClass = border === 'disable-border' ? `c-tiles--${border}` : '';
  let galleryType = 'c-image-gallery ';
  galleryType += imagesPerRow === '' ? 'c-image-gallery--4' : `c-image-gallery--${imagesPerRow}`;
  galleryType += `-${imgResolutionsPicture}`;

  return (
    <div className="bg-grey-teaser" id={sectionId}>
      <div className="grid-container">
        <div className={`c-tiles ${borderClass} c-tiles--landingpage`}>
          <Title
            title={title}
            subtitle={subtitle}
            Format={isChild ? TitleFormats.h4 : TitleFormats.h2}
            titlePreviewId="#st_title"
            subtitlePreviewId="#st_subtitle"
            isSectionReference={isSectionReference}
            formatClassName="h3"
          />
          <div
            className={`${galleryType} grid-x grid-margin-x`}
            data-preview-id={catalogName && !isSectionReference ? `#${catalogName}` : undefined}
          >
            {imageGallery.length > 0 && (
              <Thumbnail
                imageGallery={imageGallery}
                maxLength={maxLength}
                setMaxLength={setMaxLength}
                isSectionReference={isSectionReference}
                setIsOpen={setIsOpen}
                setCurrentItemIndex={setCurrentItemIndex}
              />
            )}
          </div>
          {isOpen && (
            <GalleryLightbox
              imageGallery={imageGallery}
              maxLength={maxLength}
              currentItemIndex={currentItemIndex}
              setCurrentItemIndex={setCurrentItemIndex}
              setIsOpen={setIsOpen}
            />
          )}
        </div>
      </div>
    </div>
  );
}
