// types
import { ImageGalleryItem } from '../content-elements-payload';

// components
import { BUTTON_TYPES, Button } from 'components/Button/Button';
import { InnerHtml } from 'components/InnerHtml/inner-html';
import { Image } from 'components/image/image';

// utils
import { useIsMobile } from 'components/App/SizeProvider';
import { renderLinks } from 'utils/links';

interface SlidesProps {
  imageGallery: ImageGalleryItem[];
  maxLength: number;
  index: number;
  handlePrevSlide: () => void;
  handleNextSlide: () => void;
}

export default function Slides({
  imageGallery,
  maxLength,
  index,
  handlePrevSlide,
  handleNextSlide,
}: Readonly<SlidesProps>) {
  const isMobile = useIsMobile();
  const gallery = isMobile ? imageGallery.slice(0, maxLength) : imageGallery;
  const image = imageGallery[index];

  return (
    <div>
      <div className="c-image-gallery__img-wrapper">
        <Image
          data={image.pictureObject}
          src={image.picture}
          alt={image.pictureAlt}
          title={image.pictureAlt || ''}
          isInLightbox
        />
        {gallery.length > 1 && (
          <>
            <Button
              type={BUTTON_TYPES.PLAIN}
              symbol="chevron-large-left"
              className="c-image-gallery__arrow c-image-gallery__arrow--prev"
              onClick={handlePrevSlide}
            />
            <Button
              type={BUTTON_TYPES.PLAIN}
              symbol="chevron-large-right"
              className="c-image-gallery__arrow c-image-gallery__arrow--next"
              onClick={handleNextSlide}
            />
          </>
        )}
      </div>
      {image.subline && (
        <InnerHtml as="h5" content={image.subline} className="c-image-gallery__subheadline" />
      )}
      {image.content && (
        <InnerHtml as="div" content={image.content} className="c-image-gallery__content" />
      )}
      <div className="c-image-gallery__links">
        {image.link1 && Object.keys(image.link1).length > 0 && renderLinks(image.link1)}
        {image.link2 && Object.keys(image.link2).length > 0 && renderLinks(image.link2)}
      </div>
    </div>
  );
}
