// types
import type { ImageGalleryItem } from '../content-elements-payload';

// components
import { Image } from 'components/image/image';
import { ICON_COLORS, ICON_TYPES, Icon } from 'components/Icon/Icon';
import { InnerHtml } from 'components/InnerHtml/inner-html';

// utils
import { renderLinks } from 'utils/links';
import { decodingContent } from 'utils/decodingContent';

interface ThumbnailLandingpageProps {
  image: ImageGalleryItem;
  onClick: () => void;
  index: number;
  isSectionReference: boolean;
}

export default function ThumbnailLandingpage({
  image,
  onClick,
  index,
  isSectionReference,
}: Readonly<ThumbnailLandingpageProps>) {
  return (
    <div
      className="c-tiles__column small-12"
      data-preview-id={isSectionReference ? undefined : `#${index}`}
    >
      <div className="c-tiles__link">
        <div
          className="c-image-gallery__img-wrapper"
          onClick={(e) => {
            e.preventDefault();
            onClick();
          }}
          onKeyPress={(e) => {
            if (e.key === 'Enter') {
              e.preventDefault();
              onClick();
            }
          }}
          role="button"
          tabIndex={0}
        >
          <Image
            className="c-tiles__image"
            data={image.pictureObject || image.picture}
            alt={image.pictureAlt}
            title={image.pictureAlt || ''}
          />
          <Icon symbol="lens-zoom-in" color={ICON_COLORS.WHITE} type={ICON_TYPES.WEB20} />
        </div>
        <div className="c-tiles__content">
          <h4>{decodingContent(image.description)}</h4>
          <InnerHtml as="div" content={image.content} />
          <div className="c-image-gallery__links">
            {image.link1 && Object.keys(image.link1).length > 0 && renderLinks(image.link1)}
            {image.link2 && Object.keys(image.link2).length > 0 && renderLinks(image.link2)}
          </div>
        </div>
      </div>
    </div>
  );
}
