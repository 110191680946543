// types
import type { ImageGalleryItem } from '../content-elements-payload';

// components
import { Image } from 'components/image/image';
import { ICON_COLORS, ICON_TYPES, Icon } from 'components/Icon/Icon';
import { InlineEdit } from 'components/ContentCreator/InlineEdit';

// utils
import { decodingContent } from 'utils/decodingContent';

interface ThumbnailDefaultProps {
  image: ImageGalleryItem;
  onClick: () => void;
  index: number;
  isSectionReference: boolean;
}

export default function ThumbnailDefault({
  image,
  onClick,
  index,
  isSectionReference,
}: Readonly<ThumbnailDefaultProps>) {
  return (
    <div
      className="c-image-gallery__item"
      data-preview-id={isSectionReference ? undefined : `#${index}`}
    >
      <div
        className="c-image-gallery__img-wrapper"
        onClick={(e) => {
          e.preventDefault();
          onClick();
        }}
        onKeyPress={(e) => {
          if (e.key === 'Enter') {
            e.preventDefault();
            onClick();
          }
        }}
        aria-label={image.pictureAlt}
        role="button"
        tabIndex={0}
      >
        <Image
          data={image.pictureObject || image.picture}
          alt={image.pictureAlt}
          title={image.pictureAlt || ''}
        />
        <Icon symbol="lens-zoom-in" color={ICON_COLORS.WHITE} type={ICON_TYPES.WEB20} />
      </div>
      {image.description !== '' && (
        <div className="c-image-gallery__img-description">
          <InlineEdit previewId="#st_description" isSectionReference={isSectionReference}>
            {decodingContent(image.description)}
          </InlineEdit>
        </div>
      )}
    </div>
  );
}
