// utils
import { ImageGalleryItem } from '../content-elements-payload';

// components
import { Lightbox } from 'components/lightbox/lightbox';
import { InnerHtml } from 'components/InnerHtml/inner-html';
import { BUTTON_TYPES, Button } from 'components/Button/Button';
import { Translation } from 'components/Translation/Translation';

// utils
import { imageGalleryClick } from './trackingActions';
import { useIsMobile } from 'components/App/SizeProvider';
import { useTracking } from 'utils/hooks/useTracking';
import Slides from './slides';

interface GalleryLightboxProps {
  imageGallery: ImageGalleryItem[];
  maxLength: number;
  currentItemIndex: number;
  setCurrentItemIndex: (currentItemIndex: number) => void;
  setIsOpen: (isOpen: boolean) => void;
}

export default function GalleryLightbox({
  imageGallery,
  maxLength,
  currentItemIndex,
  setCurrentItemIndex,
  setIsOpen,
}: Readonly<GalleryLightboxProps>) {
  const isMobile = useIsMobile();
  const track = useTracking();
  const gallery = isMobile ? imageGallery.slice(0, maxLength) : imageGallery;
  const image = gallery[currentItemIndex];

  const handlePrevSlide = () => {
    track.trackEvent(imageGalleryClick('Prev', 'imageGalleryNav'));

    const images = isMobile ? imageGallery.slice(0, maxLength) : imageGallery;
    const index = (currentItemIndex + (images.length - 1)) % images.length;
    setCurrentItemIndex(index);
  };

  const handleNextSlide = () => {
    track.trackEvent(imageGalleryClick('Next', 'imageGalleryNav'));

    const images = isMobile ? imageGallery.slice(0, maxLength) : imageGallery;
    const index = (currentItemIndex + 1) % images.length;
    setCurrentItemIndex(index);
  };

  return (
    <Lightbox
      onCloseRequest={() => setIsOpen(false)}
      onKeyLeft={handlePrevSlide}
      onKeyRight={handleNextSlide}
    >
      <div className="c-image-gallery__item-container c-lightbox__container">
        <header className="c-image-gallery__item-container__header">
          {image.headline && (
            <InnerHtml as="h4" content={image.headline} className="c-image-gallery__headline" />
          )}
          <Button
            symbol="close"
            type={BUTTON_TYPES.PLAIN}
            onClick={() => setIsOpen(false)}
            className="c-lightbox__close"
            noTracking
          >
            <span>
              <Translation id="web20_lightbox_close" />
            </span>
          </Button>
        </header>
        {
          <Slides
            index={currentItemIndex}
            imageGallery={imageGallery}
            maxLength={maxLength}
            handlePrevSlide={handlePrevSlide}
            handleNextSlide={handleNextSlide}
          />
        }
      </div>
    </Lightbox>
  );
}
